import React from 'react';
import { Paragraph } from '@leafwell/components';

import ArrowButton from 'components/arrow-button';
import PageWrapMinimal from 'components/page-wrap-minimal';

const Custom404: React.FC = () => (
  <PageWrapMinimal title="Page not found">
    <Paragraph variant="medium">
      The page you were looking for does not exist.
    </Paragraph>
    <ArrowButton
      {...{
        className: 'mt-6',
        title: 'Back to home',
        href: '/',
      }}
    />
  </PageWrapMinimal>
);

export default Custom404;
